import PropTypes from 'prop-types'
import { detect } from 'detect-browser'
import classNames from 'classnames'

import OutdatedBrowser from 'components/OutdatedBrowser'
import Footer from 'components/Footer'

import s from './Layout.module.css'

function Layout({
  children,
  footerLess,
  className,
  globalSettings,
  contentClassName,
}) {
  const browser = detect()
  return (
    <div className={className}>
      <OutdatedBrowser visible={browser && browser.name === 'ie'} />
      <main id="layout" className={classNames(s.main, contentClassName)}>
        {children}
      </main>
      {!footerLess && <Footer globalSettings={globalSettings} />}
    </div>
  )
}

Layout.propTypes = {
  className: PropTypes.string,
  footerLess: PropTypes.bool,
  globalSettings: PropTypes.object,
  contentClassName: PropTypes.string,
}
Layout.defaultProps = {
  footerLess: false,
  className: '',
  globalSettings: {},
  contentClassName: '',
}

export default Layout
