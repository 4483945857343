import { useState } from 'react'
import PropTypes from 'prop-types'

import s from './OutdatedBrowser.module.css'

function OutdatedBrowser({ visible }) {
  const [mounted, setMounted] = useState(visible)

  const handleClick = () => {
    setMounted(false)
  }

  if (!mounted) return null

  return (
    <div className={s.container}>
      <h3 className={s.title}>Your browser is out-of-date!</h3>
      <p className={s.text}>
        Please update your browser to view this website correctly.
      </p>
      <a
        href="https://browser-update.org/update-browser.html"
        target="_blank"
        rel="noopener noreferrer"
        className={s.link}
      >
        Update my browser
      </a>
      <button type="button" className={s.btn} onClick={handleClick} />
    </div>
  )
}

OutdatedBrowser.propTypes = {
  visible: PropTypes.bool,
}
OutdatedBrowser.defaultProps = {
  visible: false,
}

export default OutdatedBrowser
